import React from 'react'

import Layout from 'src/components/layout/index'

import SEO from 'src/components/seo/index'

import styles from './index.module.scss'


class SoundLoader {

    constructor(audioContext) {
        this.audioContext = audioContext
    }

    load(url) {
        return new Promise((resolve) => {
            const request = new XMLHttpRequest()

            request.onload = (res) => {
                this.audioContext.decodeAudioData(res.currentTarget.response, (buffer) => {
                    resolve(buffer)
                })
            }

            request.open('GET', url, true)
            request.responseType = 'arraybuffer'
            request.send()
        })

    }
}

class Player {

    play() {
        const audioContext = new AudioContext()

        const loader = new SoundLoader(audioContext)

        const canvas = document.getElementById('cv')
        const context = canvas.getContext('2d')

        const size = Math.pow(2, 10)

        loader.load(`/mp3/port.mp3`).then((buffer) => {

            const analyser = audioContext.createAnalyser()
            analyser.fftSize = size
            analyser.connect(audioContext.destination)

            const gainNode = audioContext.createGain()
            gainNode.gain.value = 1
            gainNode.connect(analyser)

            const source = audioContext.createBufferSource()
            source.buffer = buffer
            source.connect(gainNode)


            const frequency = new Uint8Array(analyser.frequencyBinCount)

            canvas.width = frequency.length
            canvas.height = frequency.length

            const data = []

            source.start(0)

            const timer = setInterval(() => {
                analyser.getByteFrequencyData(frequency)
                data.push(Array.from(frequency))

                context.clearRect(0, 0, canvas.width, canvas.height)
                context.fillStyle = '#ff0000'
                context.lineWidth = canvas.width / frequency.length * 3

                context.beginPath()
                context.moveTo(0, canvas.height)

                for (let i = 0; i < frequency.length; i++) {
                    context.lineTo(i, canvas.height - frequency[i])
                }

                context.lineTo(canvas.width, canvas.height)
                context.closePath()
                context.fill()
            }, 1000 / 60)

            source.onended = () => {
                clearInterval(timer)

                console.log(data)

                const blob = new Blob([JSON.stringify(data)], { "type": "application/octet-stream" })

                document.getElementById("dl").style.display = 'inline-block'
                document.getElementById("dl").href = window.URL.createObjectURL(blob)
            }
        })
    }
}

const WavePage = () => {

    const play = () => {
        const p = new Player()
        p.play()
    }

    return (
        <Layout className={styles.wave}>
            <SEO lang="ja" title="WAVE" description="" />

            <canvas id="cv"></canvas>

            <div>
                <button onClick={play} className={styles.play}>PLAY</button>
                <a id="dl" download="wave.json" href="/" target="_blank">DL</a>
            </div>
        </Layout>
    )
}

export default WavePage
